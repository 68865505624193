import React, { FC } from 'react';
import { graphql } from 'gatsby';

import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import './GuidelinePage.scss';

const GuildlinesPage: FC<GuidelinesPageTypes.GuidelinesPageProps> = ({
  data: {
    guidelinesPage: {
      pdfFile: { url },
      pdfTitle,
      urls,
      seo,
    },
  },
}) => {
  const { title, keywords, description } = seo;

  return (
    <>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <iframe src={url} title={pdfTitle} className="branding-guideline" />
    </>
  );
};

export const query = graphql`
  query GuidelinesQuery($lang: String) {
    guidelinesPage(lang: { eq: $lang }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      pdfFile {
        url
      }
      pdfTitle
    }
  }
`;

export default GuildlinesPage;
